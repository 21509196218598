
import React, { useState, useEffect } from "react";
import { isEmpty } from 'lodash';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { getOgImage } from "../../utils/functions";
import ProductsList from "../../components/productsList/ProductsList";
import { withPrefix } from "gatsby";
import Img from "gatsby-image"
import Info from "../../images/info_green.svg"

import "./style.scss"


const SubscriptionInfoPage = (props) => {
  const {
    pageContext: {
      title, seo, uri, content, acfSubscriptionInfo
    }
  } = props;
  const {
    steps,
    infoAfterSteps,
    productsHeading,
    products,
    descriptionAfterProducts,
    productButton,
  } = acfSubscriptionInfo

  const { code: locale } = props.pageContext.wpmlTranslated.current[0];
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2);

  const [productsItems, setProductsItems] = useState([])
  useEffect(() => {
    if (products) {

      let tmp = [];
      products.forEach(element => {
        tmp.push({ ...element.product })
      });
      setProductsItems(tmp)
    }
  }, [])

  return (
    <Layout lang={lang} page="subscribtionInfo" translated={translated}>
      {
        !isEmpty(props.pageContext) ? (
          <>
            <SEO
              title={title}
translated={translated}
              seoData={seo}
              uri={uri}
              lang={lang}
              page={"subscribtionInfo"}
              // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
              openGraphImage={getOgImage(seo)}
            />
            <div className="page">

              <section className="subscribtionInfo__info">
                <div className="container">
                  <h1 className="heading heading--xl">
                    {title}
                  </h1>
                  {content ? (
                    <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
                  ) : ''}
                </div>
              </section>

              <section className="subscribtionInfo__half">
                <div className="container">
                  {steps.map((item, index) => (
                    <div key={`subscribtionInfo__item__${index}`} className="subscribtionInfo__half__item">
                      <div className="subscribtionInfo__half__item__content">
                        <div className="subscribtionInfo__half__item__wrapper">
                          <div className="subscribtionInfo__half__item__step">
                            <span>
                              {item.step}
                            </span>
                          </div>
                          <h3 className="subscribtionInfo__half__item__heading heading heading--big">
                            {item.title}
                          </h3>
                          <div className="content">
                            <div dangerouslySetInnerHTML={{ __html: item.description }}>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="subscribtionInfo__half__item__img">
                        <Img fluid={item.image.imageFile.childImageSharp.fluid} />
                      </div>
                    </div>
                  ))}
                  <div className="subscribtionInfo__half__inf0">
                    <Info />
                    <div dangerouslySetInnerHTML={{ __html: infoAfterSteps }} />
                  </div>
                </div>
              </section>

              <section className="subscribtionInfo__products"
                style={{
                  // backgroundColor: '#F8F8F8',
                  backgroundImage: `url(${withPrefix('/images/product_cat_bg.png')})`,
                  backgroundPosition: 'left top',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  // backgroundBlendMode: 'multiply'
                }}
              >
                <div className="container">
                  <h2 className="heading--normal heading--dash heading--center">
                    {productsHeading}
                  </h2>
                  <ProductsList
                    action="link"
                    productBoxButton={productButton}
                    products={productsItems}
                    columns={3}
                    grid={false}
                  />
                  <div className="subscribtionInfo__products__content content">
                    <div dangerouslySetInnerHTML={{ __html: descriptionAfterProducts }} />
                  </div>
                </div>
              </section>
            </div>
          </>
        ) : (
          <div>Something went wrong</div>
        )
      }
    </Layout >
  )
};
export default SubscriptionInfoPage;

